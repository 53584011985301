<template>
  <div class="h-[100vh] bg-slate-900 absolute w-full">
    <div class="flex w-full h-full justify-center items-center">
      <div>
        <SecuroMint class="text-slate-400 mx-auto h-6"/>

        <InvalidSite :domain="domain" v-if="invalidDomain"></InvalidSite>
        <LoadingSpin class="text-slate-400 h-4 mx-auto mt-4" v-else></LoadingSpin>
      </div>
    </div>
  </div>
</template>

<script>
import SecuroMint from "../../branding/SecuroMint";
import InvalidSite from "../card/InvalidSite";
import LoadingSpin from "../LoadingSpin";
export default {
  name: "LoadingScreen",
  components: {LoadingSpin, InvalidSite, SecuroMint},
  props: {
    domain: {
      type: String,
      required: true
    },
    invalidDomain: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped>

</style>