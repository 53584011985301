<template>
  <div>
    <LoadingSpin class="mt-6 h-4 mx-auto" />
    <p class="text-center text-sm mt-1 font-medium text-slate-400">
      Connecting to SecuroMint
    </p>
    <p class="text-xs text-center text-slate-500">
      This may take a few seconds
    </p>
  </div>
</template>

<script>
import LoadingSpin from "../LoadingSpin";
export default {
  name: "ConnectingToSecuroMint",
  components: {LoadingSpin}
}
</script>

<style scoped>

</style>