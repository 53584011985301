<template>
  <div class="max-w-md w-full bg-slate-800 min-h-fit text-slate-300 rounded-lg shadow flex flex-col">
    <slot />
  </div>
</template>

<script>
export default {
  name: "MainCard"
}
</script>

<style scoped>

</style>