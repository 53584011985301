<template>
  <h1 class="text-2xl mt-4 text-center font-bold text-white flex justify-center gap-2 items-center">
    <slot />
  </h1>
</template>

<script>
export default {
  name: "ProjectName"
}
</script>

<style scoped>

</style>