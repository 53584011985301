<template>
  <img :src="src"
       alt="Logo"
       class="project-logo mx-auto rounded-xl border-2 border-slate-800"
  >
</template>

<script>
export default {
  name: "ProjectLogo",
  props: {
    src: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.project-logo {
  height: 128px;
  width: 128px;
  display: block;
}
</style>