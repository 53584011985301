<template>
  <div class="max-w-md w-full bg-slate-800 border-b-8 border-yellow-500 text-slate-300 p-2 rounded-lg text-center">
    This mint page is currently in testnet mode.
  </div>
</template>

<script>
export default {
  name: "TestnetWarning"
}
</script>

<style scoped>

</style>