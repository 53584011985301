<template>
  <h2 class="text-xs py-0.5 flex justify-center gap-2 items-center w-max bg-slate-700 cursor-default shadow mx-auto mt-2 px-4 rounded-full uppercase font-medium mb-5">
    <CheckBadgeIcon class="h-4 text-blue-400"/>
    <span class="text-slate-200">
                Verified Creator
              </span>
  </h2>
</template>

<script>
import {CheckBadgeIcon} from "@heroicons/vue/24/solid/index.js";

export default {
  name: "VerifiedCreator",
  components: {
    CheckBadgeIcon
  }
}
</script>

<style scoped>

</style>