<template>
  <h5 class="text-center mt-5 mb-5 text-slate-500 uppercase font-bold text-xs">
    Connect your Wallet to Mint
  </h5>

  <div class="px-8 mt-0.5 flex gap-3 justify-center">
    <a class="px-6 py-1 rounded-lg bg-indigo-600 font-medium text-white d-block w-full text-center hover:bg-indigo-700 transition-colors cursor-pointer"
       @click="connect">
      Connect Wallet
    </a>
  </div>
</template>

<script>
import Web3Modal from "web3modal";
import WalletConnect from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";

export default {
  name: "ConnectWallet",
  methods: {
    async connect() {
      const modal = new Web3Modal({
        network: this.testnet ? 'goerli' : 'mainnet',
        cacheProvider: false,
        providerOptions: {
          opera: {
            package: true
          },
          walletconnect: {
            package: WalletConnect,
            options: {
              infuraId: 'ef6ce5c26f6c45dc9b843ecfecbdc2fe'
            }
          },
          binancechainwallet: {
            package: true
          },
          coinbasewallet: {
            package: CoinbaseWalletSDK,
            options: {
              appName: "SecuroMint NFT Minting",
              infuraId: 'ef6ce5c26f6c45dc9b843ecfecbdc2fe'
            }
          },
        }
      })

      const instance = await modal.connect();

      this.$emit('connected', instance);
    }
  },
  props: {
    testnet: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>