<template>
  <div class="mt-5 w-11/12 mx-auto bg-slate-700/50 p-2 rounded-lg" v-if="error.length">
    <h5 class="text-center uppercase text-xs font-medium text-red-500">
      An Error Occurred
    </h5>
    <p class="text-center" v-text="error">
    </p>
  </div>
</template>

<script>
export default {
  name: "Web3Error",
  props: {
    error: {
      type: String,
      default: ""
    }
  }
}
</script>

<style scoped>

</style>