<template>
  <div class="my-5 text-xs text-center text-white/50">
    <p><strong class="uppercase text-2xs text-white/40">Contract Address</strong></p>
    <a :href="`https://${testnet ? 'goerli.' : ''}etherscan.io/address/${address}`"
       class="flex gap-2 justify-center font-mono" title="Open in Etherscan"
       target="_blank">
      <span>{{ address }}</span>
      <MagnifyingGlassCircleIcon class="h-4"/>
    </a>
  </div>
</template>

<script>
import {MagnifyingGlassCircleIcon} from "@heroicons/vue/24/solid/index.js";

export default {
  name: "EtherscanLink",
  components: {
    MagnifyingGlassCircleIcon
  },
  props: {
    address: {
      type: String,
      required: true
    },
    testnet: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>